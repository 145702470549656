<template>
  <div>
    <v-toolbar flat>
      <v-toolbar-title>Bonus Logs</v-toolbar-title>
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-text-field
        v-model="text"
        label="Search"
        single-line
        @input="initialize"
        hide-details
      ></v-text-field>
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-combobox
        v-model="bonusTypeSelect"
        :items="bonusType"
        :loading="$store.state.progress"
        item-value="key"
        item-text="value"
        @change="initialize"
        hide-details
        label="Bonus Type"
      ></v-combobox>
      <v-divider class="mx-2" inset vertical></v-divider>

      <v-btn color="primary" dark @click="initialize" class="mb-2"
        >Search</v-btn
      >
    </v-toolbar>
    <v-data-table
      :headers="headers"
      :sort-by="['createdDate']"
      :sort-desc="[true]"
      v-if="chipTransfer"
      hide-default-footer
      :items="chipTransfer.data"
      :items-per-page="this.perPage"
      class="elevation-1"
    >
      <template v-slot:item.createdTime="{ item }">
        <div>{{ new Date(item.createdTime * 1000) | formatDate }}</div>
      </template>
      <template v-slot:item.chip="{ item }">
        <div>{{ item.chip | formatMoney }}</div>
      </template>
    </v-data-table>
    <div class="text-center pt-2">
      <v-pagination
        v-model="currentPage"
        @input="pageClick"
        :total-visible="20"
        :length="chipTransfer.totalPage"
      ></v-pagination>
    </div>
  </div>
</template>
<script>
import bonusType from "@/lib/bonusType";

export default {
  name: "BonusDetailCard",
  components: {},
  props: {
    search: {
      type: String,
      description: "search",
    },
  },
  data() {
    return {
      text: "",
      dialog: false,
      bonusType: bonusType,
      bonusTypeSelect: { key: -1, value: "All" },
      currentPage: 1,
      perPage: 50,
      chipTransfer: {},
      headers: [
        { text: "User", value: "userId" },
        { text: "Type", value: "type" },
        { text: "Sended Chip", value: "chip" },
        { text: "Ip", value: "ipAdress" },
        { text: "Created Date", value: "createdTime" },
      ],
    };
  },
  methods: {
    async initialize() {
      await this.$store.dispatch("bonusLogs/getByUserId", {
        page: this.currentPage - 1,
        size: this.perPage,
        bonusType: this.bonusTypeSelect.key,
        userId: this.text,
      });
      this.chipTransfer = this.$store.state.bonusLogs.all;
    },
    pageClick(a) {
      this.currentPage = a;
      this.initialize();
    },
  },
  async mounted() {
    this.text = this.search;
    this.initialize();
  },
};
</script>
